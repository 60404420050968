import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { observer } from "mobx-react-lite";
import useVideoControls from "services/hooks/useVideoControls";
import RequestPermission from "components/videos/RequestPermission";
import ReplaceVideo from "components/videos/ReplaceVideo";
import Toolbar from "components/videos/Toolbar";
import VideoNavigationControls from "components/ui/VideoNavigationControls";
import VideoNavigationKeyboardShortcuts from "components/ui/VideoNavigationKeyboardShortcuts";
const SetupListItem = observer(({ video }) => {
    const containerEl = React.useRef(null);
    const [gotoTime, pause, play, setVolume] = useVideoControls(video.setupVideoEl);
    const handleMouseEnter = React.useCallback(() => {
        video.setSetupVideoHovered(true);
    }, []);
    const handleMouseLeave = React.useCallback(() => {
        video.setSetupVideoHovered(false);
    }, []);
    const handleChangeVolume = (newVolume) => {
        video.setVolume(newVolume);
        setVolume(newVolume);
    };
    // Once the local file handle is present, append it to the player
    // TODO: This could be converted to observing the element property itself
    React.useEffect(() => {
        if (containerEl.current === null || video.setupVideoEl === null) {
            return;
        }
        containerEl.current.appendChild(video.setupVideoEl);
    }, [video.videoElementsCreated]);
    const renderedBody = (() => {
        // If we require the user to bring the video online
        if (video.localFileHandlePermission === "prompt") {
            return _jsx(RequestPermission, { video: video });
        }
        // If the video has gone missing for some reason
        if (video.fileMissing === true) {
            return _jsx(ReplaceVideo, { video: video });
        }
        return (_jsxs(_Fragment, { children: [_jsx("div", { ref: containerEl }), video.setupVideoEl !== null &&
                    video.duration !== null &&
                    video.setupVideoHovered === true &&
                    video.frameLength !== null && (_jsx("div", { className: "absolute bottom-0 left-0 right-0 z-10 bg-zinc-900/80 p-4", children: _jsx(VideoNavigationControls, { currentTime: video.offset, duration: video.duration, frameLength: video.frameLength, onChangeVolume: (newVolume) => handleChangeVolume(newVolume), onGotoTime: (time) => gotoTime(time), onPause: () => pause(), onPlay: () => play(), playing: video.setupVideoPlaying === true, seeking: video.setupVideoSeeking === true, videoEl: video.setupVideoEl, volume: video.volume }) })), video.setupVideoEl !== null &&
                    video.duration !== null &&
                    video.frameLength !== null && (_jsx(VideoNavigationKeyboardShortcuts, { frameLength: video.frameLength, keyboardShortcutsEnabled: video.setupVideoHovered === true, onGotoTime: (time) => gotoTime(time), onPause: () => pause(), onPlay: () => play(), playing: video.setupVideoPlaying === true, seeking: video.setupVideoSeeking === true, videoEl: video.setupVideoEl }))] }));
    })();
    return (_jsxs("div", { className: "relative", onMouseEnter: () => handleMouseEnter(), onMouseLeave: () => handleMouseLeave(), children: [(video.setupVideoHovered === true ||
                video.fileMissing === true ||
                video.localFileHandlePermission === "prompt") && (_jsx("div", { className: "absolute top-0 left-0 right-0 z-40 bg-zinc-900/80 p-4", children: _jsx(Toolbar, { video: video }) })), renderedBody] }));
});
export default SetupListItem;
