var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import consola from "consola";
import { computed, reaction } from "mobx";
import { model, Model, types, tProp, idProp, findParent, modelAction, prop, } from "mobx-keystone";
import { RecordNotFound } from "services/errors";
import bus from "services/bus";
import BookmarkPage from "./bookmark_page";
import Session from "./session";
import { bookmarkPageRef } from "./references";
let Bookmark = class Bookmark extends Model({
    id: idProp,
    createdAt: tProp(types.number, Date.now()),
    // Is this bookmark currently active? (i.e. displaying highlighted in the
    // sidebar and displaying drawing from the current page)
    active: tProp(types.boolean, false).withSetter(),
    // Will trigger the bookmark to be active after the next seek. This is useful
    // when we restore from being saved or want to activate the bookmark after
    // clicking on its timecode
    activateAfterNextSeek: tProp(types.boolean, false).withSetter(),
    // Is this bookmark currently being edited?
    editingInProgress: tProp(types.boolean, false).withSetter(),
    selectedBookmarkPageRef: prop(),
    bookmarkPages: tProp(types.array(types.model(() => BookmarkPage)), () => []),
}) {
    onAttachedToRootStore() {
        const reactionDisposer = reaction(() => this.active, async (active) => {
            if (active === true) {
                consola.info(`Bookmark was activated: ${this.timestamp}`);
                if (this.selectedBookmarkPage.video.reviewVideoEl === null) {
                    return;
                }
                //this.session.selectVideo(this.selectedBookmarkPage.video);
                this.selectedBookmarkPage.video.reviewVideoEl.pause();
                bus.emit("video.pause", this.selectedBookmarkPage.video);
            }
        }, {
            fireImmediately: true,
        });
        if (this.active === true) {
            this.setActivateAfterNextSeek(true);
        }
        return () => {
            reactionDisposer();
        };
    }
    /**
     * Delete this bookmark
     */
    delete() {
        consola.info(`Deleting bookmark: ${this.timestamp}`);
        this.session.removeBookmark(this);
    }
    addBookmarkPage(bookmarkPage) {
        consola.info(`Adding bookmark page: ${bookmarkPage.id}`);
        this.bookmarkPages.push(bookmarkPage);
    }
    selectBookmarkPage(bookmarkPage) {
        consola.info(`Selecting bookmark page: ${bookmarkPage.id}`);
        if (!this.bookmarkPages.includes(bookmarkPage))
            throw new RecordNotFound("Tried to select video but it did not belong to this session");
        this.selectedBookmarkPageRef = bookmarkPageRef(bookmarkPage);
    }
    /**
     * Creates a new bookmark page under this bookmark. Will automatically
     * duplicate the contents of the currently selected bookmark then
     * switch to the bookmark page.
     */
    deleteBookmarkPage(bookmarkPage) {
        consola.info(`Deleting bookmark page: ${bookmarkPage.id}`);
        return (this.bookmarkPages = this.bookmarkPages.filter((innerBookmarkPage) => {
            return innerBookmarkPage.id !== bookmarkPage.id;
        }));
    }
    /**
     * The session time of the bookmark page.
     */
    get timestamp() {
        return this.bookmarkPages[0].timestamp;
    }
    /**
     * The "real" time of bookmark page.
     */
    get videoTimestamp() {
        return this.bookmarkPages[0].videoTimestamp;
    }
    get session() {
        return findParent(this, (p) => p instanceof Session);
    }
    get bookmarkPageCount() {
        return this.bookmarkPages.length;
    }
    get hasBookmarkPages() {
        return this.bookmarkPageCount > 0;
    }
    get sortedBookmarkPages() {
        return [...this.bookmarkPages].sort((a, b) => {
            return a.createdAt - b.createdAt;
        });
    }
    get selectedBookmarkPage() {
        return this.selectedBookmarkPageRef.current;
    }
    get hasBeenSeen() {
        return this.session.hasSeenBookmark(this);
    }
};
__decorate([
    modelAction
], Bookmark.prototype, "delete", null);
__decorate([
    modelAction
], Bookmark.prototype, "addBookmarkPage", null);
__decorate([
    modelAction
], Bookmark.prototype, "selectBookmarkPage", null);
__decorate([
    modelAction
], Bookmark.prototype, "deleteBookmarkPage", null);
__decorate([
    computed
], Bookmark.prototype, "timestamp", null);
__decorate([
    computed
], Bookmark.prototype, "videoTimestamp", null);
__decorate([
    computed
], Bookmark.prototype, "session", null);
__decorate([
    computed
], Bookmark.prototype, "bookmarkPageCount", null);
__decorate([
    computed
], Bookmark.prototype, "hasBookmarkPages", null);
__decorate([
    computed
], Bookmark.prototype, "sortedBookmarkPages", null);
__decorate([
    computed
], Bookmark.prototype, "selectedBookmarkPage", null);
__decorate([
    computed
], Bookmark.prototype, "hasBeenSeen", null);
Bookmark = __decorate([
    model("VodonPlayer/Bookmark")
], Bookmark);
export default Bookmark;
