import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { TbEye, TbBookmark } from "react-icons/tb";
import TimeAgo from "components/ui/TimeAgo";
import Edit from "components/sessions/Edit";
import Delete from "components/sessions/Delete";
const Card = observer(({ session }) => {
    const [heroImageIndex, setHeroImageIndex] = React.useState(0);
    const [active, setActive] = React.useState(false);
    const createdAtDate = new Date(session.createdAt);
    const hasAdditionalImages = session.videos.length > 1;
    const handleMouseEnter = () => {
        if (hasAdditionalImages === false) {
            return;
        }
        setActive(true);
        setHeroImageIndex(1);
    };
    const handleMouseLeave = () => {
        if (hasAdditionalImages === false) {
            return;
        }
        setActive(false);
        setHeroImageIndex(0);
    };
    React.useEffect(() => {
        if (active === false) {
            return;
        }
        const interval = setInterval(() => {
            setHeroImageIndex(heroImageIndex === session.videos.length - 1 ? 0 : heroImageIndex + 1);
        }, 750);
        return () => {
            clearInterval(interval);
        };
    }, [active, session, heroImageIndex]);
    const video = session.videos[heroImageIndex];
    return (_jsxs("div", { className: "flex flex-col bg-zinc-800", children: [_jsx("div", { onMouseEnter: () => handleMouseEnter(), onMouseLeave: () => handleMouseLeave(), children: _jsx(Link, { className: "link", to: `/sessions/${session.id}/review`, children: video !== undefined &&
                        video.videoSyncFrame !== null &&
                        video.videoSyncFrame.url !== null && (_jsx("img", { src: video.videoSyncFrame.url, className: "w-full h-full object-contain" })) }) }), _jsxs("div", { className: "p-4 header-3 border-b border-zinc-600 flex items-center", children: [_jsx("h2", { className: "flex-grow", children: _jsx(Link, { className: "link", to: `/sessions/${session.id}/review`, children: session.name }) }), _jsxs("div", { className: "flex items-center gap-4 text-white/70", children: [_jsxs("div", { className: "flex items-center gap-1", children: [_jsx(TbEye, { size: 24 }), session.videos.length] }), _jsxs("div", { className: "flex items-center gap-1", children: [_jsx(TbBookmark, { size: 24 }), session.bookmarks.length] })] })] }), _jsxs("div", { className: "p-4 flex items-stretch", children: [_jsxs("div", { className: "flex-grow", children: [createdAtDate.toLocaleDateString(), _jsx("span", { className: "block text-sm text-white/50", children: _jsx(TimeAgo, { date: createdAtDate }) })] }), _jsxs("div", { className: "flex items-center gap-4", children: [_jsx(Edit, { session: session }), _jsx(Delete, { session: session })] })] })] }));
});
export default Card;
