import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "services/models/root";
import Card from "components/sessions/Card";
const List = observer(() => {
    const store = React.useContext(RootStoreContext);
    const renderedSessionCards = store.sortedSessions.map((session) => {
        return _jsx(Card, { session: session }, session.id);
    });
    if (renderedSessionCards.length === 0) {
        return _jsx("em", { children: "No sessions created yet" });
    }
    return (_jsx("div", { className: "grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6", children: renderedSessionCards }));
});
export default List;
