import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from "react";
import { TbMoodCry } from "react-icons/tb";
import { MissingRequiredAPIs } from "services/errors";
import * as Sentry from "@sentry/browser";
import { clearLocalData } from "services/state";
class ErrorBoundary extends Component {
    state = {
        error: null,
    };
    static getDerivedStateFromError(error) {
        return {
            error,
        };
    }
    componentDidCatch(error, errorInfo) {
        if (error instanceof MissingRequiredAPIs) {
            return;
        }
        Sentry.captureException(error);
    }
    handleClickReset() {
        if (window.confirm("This will reset all data in Vodon Player, you may want export your data first") === false) {
            return;
        }
        clearLocalData();
    }
    render() {
        const { error } = this.state;
        if (error !== null) {
            const renderedError = (() => {
                switch (true) {
                    case error instanceof MissingRequiredAPIs:
                        return (_jsxs("p", { children: ["You are missing JavaScript APIs required for the Vodon Player to work. Please use a Chromium based browser like", " ", _jsx("strong", { children: "Google Chrome" }), " or", " ", _jsx("strong", { children: "Microsoft Edge" }), " which supports these APIs."] }));
                    default:
                        return _jsx("p", { children: "Unknown error" });
                }
            })();
            return (_jsx("div", { className: "w-screen h-screen flex items-center justify-center text-center p-8", children: _jsxs("div", { className: "max-w-prose", children: [_jsx("div", { className: "flex items-center justify-center mb-6", children: _jsx(TbMoodCry, { size: 120 }) }), _jsx("h1", { className: "header-1", children: "Sorry, we encountered an error!" }), _jsx("div", { className: "mt-6 text-white/80", children: renderedError }), _jsx("p", { className: "my-6 font-mono bg-zinc-800 p-4", children: error.message }), _jsx("p", { className: "my-6", children: _jsxs("div", { className: "flex items-center justify-center gap-4", children: [_jsx("button", { className: "btn btn-primary", children: "Export data" }), _jsx("button", { className: "btn btn-warning", onClick: this.handleClickReset, children: "Reset Vodon Player" })] }) }), _jsxs("div", { className: "mt-6", children: [_jsx("p", { children: "If you are getting this error, please export your data then reset the player using the buttons above. If you want to recover your sessions, please send the exported data to me and I'll help you recover it." }), _jsxs("p", { className: "mt-3 text-sm", children: ["Email:", _jsx("br", {}), _jsx("a", { className: "underline text-sky-500", href: "mailto:sam@richardson.co.nz", children: "sam@richardson.co.nz" })] }), _jsxs("p", { className: "mt-3 text-sm", children: ["Discord:", _jsx("br", {}), _jsx("pre", { children: "Rodeoclash#4192" })] })] })] }) }));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
