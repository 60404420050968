import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { activate } from "services/bookmark_pages";
const Pagination = observer(({ bookmark }) => {
    const handleClickPageNumber = (bookmarkPage) => {
        activate(bookmarkPage, false);
    };
    const renderedPages = bookmark.sortedBookmarkPages.map((bookmarkPage, index) => {
        const classes = classNames({
            "navlink border-l border-stone-700": true,
            ["active"]: bookmarkPage.active === true,
            "cursor-not-allowed": bookmark.editingInProgress === true,
        });
        return (_jsx("li", { className: "flex items-stretch relative", children: _jsx("a", { onClick: () => handleClickPageNumber(bookmarkPage), className: classes, children: index + 1 }) }, bookmarkPage.id));
    });
    return _jsx(_Fragment, { children: renderedPages });
});
export default Pagination;
