import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { FRAME_ADVANCE_INTERVAL } from "services/settings";
export var Direction;
(function (Direction) {
    Direction[Direction["Forward"] = 0] = "Forward";
    Direction[Direction["Back"] = 1] = "Back";
})(Direction || (Direction = {}));
const FrameAdjust = ({ direction, onClick, seeking }) => {
    const [active, setActive] = React.useState(null);
    const handleMouseDown = () => {
        setActive(true);
        onClick();
    };
    const handleMouseUp = () => {
        setActive(false);
    };
    const handleMouseLeave = () => {
        setActive(false);
    };
    React.useEffect(() => {
        if (active === false || active === null || seeking === true) {
            return;
        }
        const interval = setInterval(() => {
            onClick();
        }, FRAME_ADVANCE_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [active, seeking]);
    const renderedLeftArrow = (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: 1.5, stroke: "currentColor", className: "w-6 h-6", children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M15.75 19.5L8.25 12l7.5-7.5" }) }));
    const renderedRightArrow = (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: 1.5, stroke: "currentColor", className: "w-6 h-6", children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M8.25 4.5l7.5 7.5-7.5 7.5" }) }));
    return (_jsx("button", { onMouseDown: () => handleMouseDown(), onMouseUp: () => handleMouseUp(), onMouseLeave: () => handleMouseLeave(), children: direction === Direction.Back ? renderedLeftArrow : renderedRightArrow }));
};
export default FrameAdjust;
