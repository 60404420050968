import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
const RichTextEditor = ({ content, onUpdate }) => {
    const editor = useEditor({
        autofocus: "end",
        editable: true,
        extensions: [StarterKit],
        content,
        onUpdate: ({ editor }) => {
            onUpdate(editor.getJSON());
        },
    });
    React.useEffect(() => {
        if (!editor) {
            return;
        }
        editor.commands.setContent(content);
    }, [content]);
    return _jsx(EditorContent, { editor: editor });
};
export default RichTextEditor;
