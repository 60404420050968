import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import consola from "consola";
import { TDShapeType } from "vendor/tldraw/index.js";
import classNames from "classnames";
import { TbClick, TbPencil, TbArrowRightCircle, TbLine, TbRectangle, TbCircle, TbTrash, TbCursorText, TbNote, } from "react-icons/tb";
import Tooltip from "components/ui/Tooltip";
import DrawingSelectColour from "./DrawingSelectColour";
import DrawingSelectDash from "./DrawingSelectDash";
import DrawingSelectSize from "./DrawingSelectSize";
const DrawingControls = ({ app }) => {
    const selectTool = (type) => {
        app.selectTool(type);
        app.toggleToolLock();
    };
    // @ts-ignore
    const activeTool = app.useStore((s) => s.appState.activeTool);
    const handlePickSelect = () => {
        consola.info("Using tool: select");
        selectTool("select");
    };
    const handlePickDraw = () => {
        consola.info(`Using tool: ${TDShapeType.Draw}`);
        selectTool(TDShapeType.Draw);
    };
    const handlePickArrow = () => {
        consola.info(`Using tool: ${TDShapeType.Arrow}`);
        selectTool(TDShapeType.Arrow);
    };
    const handlePickLine = () => {
        consola.info(`Using tool: ${TDShapeType.Line}`);
        selectTool(TDShapeType.Line);
    };
    const handlePickRectangle = () => {
        consola.info(`Using tool: ${TDShapeType.Rectangle}`);
        selectTool(TDShapeType.Rectangle);
    };
    const handlePickEllipse = () => {
        consola.info(`Using tool: ${TDShapeType.Ellipse}`);
        selectTool(TDShapeType.Ellipse);
    };
    const handlePickText = () => {
        consola.info(`Using tool: ${TDShapeType.Text}`);
        selectTool(TDShapeType.Text);
    };
    const handlePickSticky = () => {
        consola.info(`Using tool: ${TDShapeType.Sticky}`);
        selectTool(TDShapeType.Sticky);
    };
    const handleClickTrash = () => {
        if (window.confirm("This will remove your drawing") === false) {
            return;
        }
        consola.info(`Trashing drawing`);
        app.deleteAll();
    };
    const base = {
        "block p-2": true,
    };
    const baseClasses = classNames(base);
    const selectClasses = classNames({
        ...base,
        "bg-zinc-500": activeTool === "select",
    });
    const pencilClasses = classNames({
        ...base,
        "bg-zinc-500": activeTool === TDShapeType.Draw,
    });
    const arrowClasses = classNames({
        ...base,
        "bg-zinc-500": activeTool === TDShapeType.Arrow,
    });
    const lineClasses = classNames({
        ...base,
        "bg-zinc-500": activeTool === TDShapeType.Line,
    });
    const rectangleClasses = classNames({
        ...base,
        "bg-zinc-500": activeTool === TDShapeType.Rectangle,
    });
    const ellipseClasses = classNames({
        ...base,
        "bg-zinc-500": activeTool === TDShapeType.Ellipse,
    });
    const textClasses = classNames({
        ...base,
        "bg-zinc-500": activeTool === TDShapeType.Text,
    });
    const stickyClasses = classNames({
        ...base,
        "bg-zinc-500": activeTool === TDShapeType.Sticky,
    });
    const colourSwatchSelectClasses = classNames({
        "bg-zinc-600": true,
        ...base,
    });
    const dashSelectClasses = classNames({
        "bg-zinc-600": true,
        ...base,
    });
    const sizeSelectClasses = classNames({
        "bg-zinc-600": true,
        ...base,
    });
    return (_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(Tooltip, { content: "Select tool", children: _jsx("button", { className: selectClasses, onClick: () => handlePickSelect(), children: _jsx(TbClick, {}) }) }), _jsx(Tooltip, { content: "Pencil tool (p)", children: _jsx("button", { className: pencilClasses, onClick: () => handlePickDraw(), children: _jsx(TbPencil, {}) }) }), _jsx(Tooltip, { content: "Arrow tool (r)", children: _jsx("button", { className: arrowClasses, onClick: () => handlePickArrow(), children: _jsx(TbArrowRightCircle, {}) }) }), _jsx(Tooltip, { content: "Line tool (l)", children: _jsx("button", { className: lineClasses, onClick: () => handlePickLine(), children: _jsx(TbLine, {}) }) }), _jsx(Tooltip, { content: "Box tool (b)", children: _jsx("button", { className: rectangleClasses, onClick: () => handlePickRectangle(), children: _jsx(TbRectangle, {}) }) }), _jsx(Tooltip, { content: "Circle tool (c)", children: _jsx("button", { className: ellipseClasses, onClick: () => handlePickEllipse(), children: _jsx(TbCircle, {}) }) }), _jsx(Tooltip, { content: "Text tool (t)", children: _jsx("button", { className: textClasses, onClick: () => handlePickText(), children: _jsx(TbCursorText, {}) }) }), _jsx(Tooltip, { content: "Sticky notes (n)", children: _jsx("button", { className: stickyClasses, onClick: () => handlePickSticky(), children: _jsx(TbNote, {}) }) }), _jsx("hr", {}), _jsx("div", { className: colourSwatchSelectClasses, children: _jsx(DrawingSelectColour, { app: app }) }), _jsx("div", { className: dashSelectClasses, children: _jsx(DrawingSelectDash, { app: app }) }), _jsx("div", { className: sizeSelectClasses, children: _jsx(DrawingSelectSize, { app: app }) }), _jsx("hr", {}), _jsx(Tooltip, { content: "Trash drawing (x)", children: _jsx("button", { className: baseClasses, onClick: () => handleClickTrash(), children: _jsx(TbTrash, {}) }) })] }));
};
export default DrawingControls;
