import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import PQueue from "p-queue";
import { createLocalVideoInSession } from "services/videos";
import { pickerOpts } from "services/videos";
const extendedPickerOpts = {
    ...pickerOpts,
    multiple: true,
};
const AddLocal = observer(({ session }) => {
    const handleClick = async () => {
        const queue = new PQueue({ concurrency: 1 });
        const fileHandles = await window.showOpenFilePicker(extendedPickerOpts);
        fileHandles.forEach(async (fileHandle) => {
            await queue.add(() => createLocalVideoInSession(session, fileHandle));
        });
    };
    return (_jsx("button", { className: "btn btn-primary", onClick: () => handleClick(), children: "Add new video" }));
});
export default AddLocal;
