import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { SizeStyle } from "vendor/tldraw/index.js";
import PopoutControl from "components/ui/Drawing/PopoutControl";
import Tooltip from "components/ui/Tooltip";
const sizes = {
    [SizeStyle.Small]: "S",
    [SizeStyle.Medium]: "M",
    [SizeStyle.Large]: "L",
};
const DrawingSelectSize = ({ app }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const currentStyle = app.useStore((s) => s.appState.currentStyle);
    const handleDashPick = React.useCallback((size) => {
        app.style({ size });
        setIsOpen(false);
    }, [app]);
    const handleClickSwatch = () => {
        setIsOpen(!isOpen);
    };
    const handleRequestClose = () => {
        setIsOpen(false);
    };
    const sizesData = Object.entries(sizes);
    const renderedSizes = sizesData.map(([key, value]) => {
        return (_jsx(Tooltip, { content: key, children: _jsx("button", { className: "w-6 h-6", onClick: () => handleDashPick(key), children: value }) }, key));
    });
    const renderedPopup = (_jsx("div", { className: "bg-stone-800 h-12 flex items-center gap-2 px-3", children: renderedSizes }));
    // @ts-ignore
    const currentSize = sizes[currentStyle.size].slice(0, 1).toUpperCase();
    return (_jsx(PopoutControl, { open: isOpen, onRequestClose: () => handleRequestClose(), popup: renderedPopup, children: _jsx(Tooltip, { content: "Line size", children: _jsx("div", { className: "w-8 h-8 flex items-center justify-center", onClick: () => handleClickSwatch(), children: currentSize }) }) }));
};
export default DrawingSelectSize;
