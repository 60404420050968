var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { computed } from "mobx";
import consola from "consola";
import { stringToFilename } from "services/import_export";
import { model, modelAction, Model, types, tProp, idProp, prop, getRoot, objectMap, } from "mobx-keystone";
import { RecordNotFound } from "services/errors";
import Video from "./video";
import Bookmark from "./bookmark";
import { videoRef } from "./references";
import { VIDEO_FUDGE_FACTOR } from "services/videos";
let Session = class Session extends Model({
    id: idProp,
    name: tProp(types.string).withSetter(),
    createdAt: tProp(types.number, Date.now()),
    bookmarks: tProp(types.array(types.model(() => Bookmark)), () => []).withSetter(),
    selectedVideoRef: prop(),
    showBookmarksPanel: tProp(types.boolean, true),
    showReviewVideoPanel: tProp(types.boolean, true),
    videos: tProp(types.array(types.model(() => Video)), () => []),
    seenBookmarkIds: prop(() => objectMap()),
}) {
    update(values) {
        consola.info(`Updating session with: ${JSON.stringify(values)}`);
        this.setName(values.name);
    }
    delete() {
        consola.info(`Removing session: ${this.name}`);
        this.root.removeSession(this);
    }
    addVideo(video) {
        consola.info(`Adding video to session: ${video.name}`);
        return this.videos.push(video);
    }
    async removeVideo(video) {
        consola.info(`Removing video from session: ${video.name}`);
        this.videos = this.videos.filter((innerVideo) => innerVideo.id !== video.id);
    }
    /**
     * Selects this video in the session. Selecting the video means switching to
     * it in the main window.
     * @param video The video to be selected
     */
    selectVideo(video) {
        consola.info(`Selecting video: ${video.name}`);
        if (!this.videos.includes(video))
            throw new RecordNotFound("Tried to select video but it did not belong to this session");
        this.selectedVideoRef = videoRef(video);
    }
    /**
     * Adds a bookmark to this session.
     * @param bookmark The bookmark to add
     */
    addBookmark(bookmark) {
        consola.info(`Adding bookmark: ${bookmark.timestamp}`);
        this.bookmarks.push(bookmark);
    }
    /**
     * Removes a bookmark from this session
     * @param bookmark The bookmark to remove
     */
    removeBookmark(bookmark) {
        consola.info(`Removing bookmark: ${bookmark.timestamp}`);
        this.setBookmarks(this.bookmarks.filter((innerBookmark) => {
            return innerBookmark.id !== bookmark.id;
        }));
    }
    toggleReviewVideoPanel() {
        consola.info(`Toggle video panel to: ${!this.showReviewVideoPanel}`);
        this.showReviewVideoPanel = !this.showReviewVideoPanel;
    }
    toggleBookmarksPanel() {
        consola.info(`Toggle bookmarks panel to: ${!this.showBookmarksPanel}`);
        this.showBookmarksPanel = !this.showBookmarksPanel;
    }
    deactivateBookmarks() {
        consola.info(`Deactivate all bookmarks`);
        this.bookmarks.forEach((bookmark) => {
            bookmark.setActive(false);
        });
    }
    /**
     * Push a specific bookmark onto the seen list.
     * @param bookmark The bookmark that was seen
     */
    addToSeenBookmarkIds(bookmark) {
        consola.info(`Adding bookmark to seen bookmarks: ${bookmark.timestamp}`);
        this.seenBookmarkIds.set(bookmark.id, true);
    }
    /**
     * Bookmarks after this time will be removed from the seen bookmark tracking.
     */
    unseeBookmarksAfter(timestamp) {
        consola.info(`Unseeing bookmarks after: ${timestamp}`);
        this.sortedBookmarks.forEach((bookmark) => {
            const bookmarkAhead = this.currentTime === null ||
                bookmark === undefined ||
                bookmark.timestamp === null ||
                bookmark.timestamp > timestamp;
            if (bookmarkAhead === true) {
                this.seenBookmarkIds.set(bookmark.id, false);
            }
        });
    }
    /**
     * Bookmarks before this time will be added to the bookmark seen tracking.
     */
    seeBookmarksBefore(timestamp) {
        consola.info(`Seeing bookmarks before: ${timestamp}`);
        this.sortedBookmarks.forEach((bookmark) => {
            const bookmarkBehind = this.currentTime === null ||
                bookmark === undefined ||
                bookmark.timestamp === null ||
                bookmark.timestamp < timestamp;
            if (bookmarkBehind === true) {
                this.seenBookmarkIds.set(bookmark.id, true);
            }
        });
    }
    get root() {
        return getRoot(this);
    }
    /**
     * Returns the value of the shortest video offset in the session.
     */
    get shortestNormalisedOffset() {
        return this.videos.reduce((acc, video) => {
            return video.offset < acc ? video.offset : acc;
        }, Infinity);
    }
    get largestNormalisedOffset() {
        return this.videos.reduce((acc, video) => {
            return video.normalisedOffset > acc ? video.normalisedOffset : acc;
        }, 0);
    }
    /**
     * Returns the current time for the entire "session".
     */
    get currentTime() {
        if (this.hasVideos === false) {
            return null;
        }
        const video = this.videos.find((video) => {
            return video.beginsAt === 0;
        });
        if (video === undefined) {
            return null;
        }
        return video.currentTimeInSession;
    }
    get selectedVideo() {
        return this.selectedVideoRef ? this.selectedVideoRef.current : null;
    }
    get activeBookmark() {
        return this.bookmarks.find((bookmark) => {
            return bookmark.active === true;
        });
    }
    get videoCount() {
        return this.videos.length;
    }
    get hasVideos() {
        return this.videoCount > 0;
    }
    get sortedBookmarks() {
        return [...this.bookmarks].sort((a, b) => {
            return (a.timestamp || 0) - (b.timestamp || 0);
        });
    }
    get bookmarkPages() {
        return this.bookmarks.flatMap((bookmark) => bookmark.bookmarkPages);
    }
    /**
     * The videos in the session as a map based on their indexed. Used for easy
     * lookups when switching by the number hotkeys.
     */
    get indexedVideos() {
        return this.videos.reduce((acc, video) => {
            return {
                ...acc,
                [video.index.toString()]: video,
            };
        }, {});
    }
    get filename() {
        return stringToFilename(this.name);
    }
    /**
     * The full duration of videos in the session is equal to the video with the
     * longest duration + the offset of it.
     */
    get duration() {
        return this.videos.reduce((acc, video) => {
            if (acc === null) {
                return video.normalisedDuration;
            }
            if (video.normalisedDuration === null) {
                return acc;
            }
            if (video.normalisedDuration > acc) {
                return video.normalisedDuration;
            }
            return acc;
        }, null);
    }
    get bookmarkPresent() {
        return this.bookmarks.some((bookmark) => {
            if (this.currentTime === null || bookmark.timestamp === null) {
                return;
            }
            return (bookmark.timestamp > this.currentTime - VIDEO_FUDGE_FACTOR &&
                bookmark.timestamp < this.currentTime + VIDEO_FUDGE_FACTOR);
        });
    }
    getVideoById(id) {
        return this.videos.find((video) => video.id === id);
    }
    getBookmarkById(id) {
        return this.bookmarks.find((bookmark) => bookmark.id === id);
    }
    /**
     * Have we seen this bookmark id yet?
     * @param bookmark
     */
    hasSeenBookmark(bookmark) {
        return this.seenBookmarkIds.get(bookmark.id) === true;
    }
    /**
     * Is the given video the one that is currently selected in the session?
     */
    isCurrentVideo(video) {
        return this.selectedVideo?.id === video.id;
    }
};
__decorate([
    modelAction
], Session.prototype, "update", null);
__decorate([
    modelAction
], Session.prototype, "delete", null);
__decorate([
    modelAction
], Session.prototype, "addVideo", null);
__decorate([
    modelAction
], Session.prototype, "removeVideo", null);
__decorate([
    modelAction
], Session.prototype, "selectVideo", null);
__decorate([
    modelAction
], Session.prototype, "addBookmark", null);
__decorate([
    modelAction
], Session.prototype, "removeBookmark", null);
__decorate([
    modelAction
], Session.prototype, "toggleReviewVideoPanel", null);
__decorate([
    modelAction
], Session.prototype, "toggleBookmarksPanel", null);
__decorate([
    modelAction
], Session.prototype, "deactivateBookmarks", null);
__decorate([
    modelAction
], Session.prototype, "addToSeenBookmarkIds", null);
__decorate([
    modelAction
], Session.prototype, "unseeBookmarksAfter", null);
__decorate([
    modelAction
], Session.prototype, "seeBookmarksBefore", null);
__decorate([
    computed
], Session.prototype, "root", null);
__decorate([
    computed
], Session.prototype, "shortestNormalisedOffset", null);
__decorate([
    computed
], Session.prototype, "largestNormalisedOffset", null);
__decorate([
    computed
], Session.prototype, "currentTime", null);
__decorate([
    computed
], Session.prototype, "selectedVideo", null);
__decorate([
    computed
], Session.prototype, "activeBookmark", null);
__decorate([
    computed
], Session.prototype, "videoCount", null);
__decorate([
    computed
], Session.prototype, "hasVideos", null);
__decorate([
    computed
], Session.prototype, "sortedBookmarks", null);
__decorate([
    computed
], Session.prototype, "bookmarkPages", null);
__decorate([
    computed
], Session.prototype, "indexedVideos", null);
__decorate([
    computed
], Session.prototype, "filename", null);
__decorate([
    computed
], Session.prototype, "duration", null);
__decorate([
    computed
], Session.prototype, "bookmarkPresent", null);
Session = __decorate([
    model("VodonPlayer/Session")
], Session);
export default Session;
