import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { getRatioDimensions } from "services/layout";
import { observer } from "mobx-react-lite";
const VideoSizer = observer(({ originalWidth, originalHeight, children, onMount }) => {
    const ref = React.useRef(null);
    const [dimensions, setDimensions] = React.useState(null);
    React.useLayoutEffect(() => {
        const handleResize = () => {
            if (ref.current === null) {
                return;
            }
            const [width, height, scale] = getRatioDimensions(originalWidth, originalHeight, ref.current);
            const dimensions = {
                width,
                height,
                scale,
            };
            setDimensions(dimensions);
            if (onMount !== undefined) {
                onMount(dimensions);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsx("div", { ref: ref, className: "w-full h-full flex items-center justify-center", children: dimensions && children(dimensions) }));
});
export default VideoSizer;
