import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { observer } from "mobx-react-lite";
import Draggable from "react-draggable";
import Show from "components/bookmarks/Show";
import Edit from "components/bookmarks/Edit";
import Container from "components/bookmarks/Container";
const Floating = observer(({ bookmark }) => {
    const nodeRef = React.useRef(null);
    const renderedContents = (() => {
        if (bookmark.editingInProgress === true) {
            return (_jsx(Container, { bookmark: bookmark, children: _jsx(Edit, { bookmark: bookmark }) }, bookmark.id));
        }
        else {
            return (_jsx(Container, { bookmark: bookmark, children: _jsx(Show, { bookmark: bookmark }) }, bookmark.id));
        }
    })();
    const style = {
        backgroundImage: "linear-gradient(45deg, #0c4a6e 25%, #075985 25%, #075985 50%, #0c4a6e 50%, #0c4a6e 75%, #075985 75%, #075985 100%)",
        backgroundSize: "56.57px 56.57px",
    };
    return (_jsx(Draggable, { handle: ".floating-drag-handle", bounds: "parent", nodeRef: nodeRef, children: _jsxs("div", { className: "w-96 pointer-events-auto bg-zinc-800", ref: nodeRef, children: [_jsx("div", { style: style, className: "cursor-move h-6 floating-drag-handle" }), _jsx("div", { children: renderedContents })] }) }));
});
export default Floating;
