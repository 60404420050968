import { jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { secondsToHms } from "services/time";
import { create } from "services/bookmarks";
const AddBookmark = observer(({ video }) => {
    const [busy, setBusy] = React.useState(false);
    const handleClick = async () => {
        if (busy === true) {
            return;
        }
        setBusy(true);
        await create(video);
        setBusy(false);
    };
    if (video.session.bookmarkPresent === true) {
        return (_jsxs("button", { className: "btn btn-primary btn-disabled block w-full cursor-not-allowed", disabled: true, children: ["Bookmark exists at ", secondsToHms(video.currentTimeInSession)] }));
    }
    return (_jsxs("button", { className: "btn btn-primary block w-full", onClick: () => handleClick(), disabled: busy, children: ["Add bookmark at ", secondsToHms(video.currentTimeInSession)] }));
});
export default AddBookmark;
