import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { observer } from "mobx-react-lite";
import Modal from "components/ui/Modal";
import ModalHeader from "components/ui/ModalHeader";
import ModalBody from "components/ui/ModalBody";
import ModalControls from "components/ui/ModalControls";
const Information = observer(({ video }) => {
    const [informationOpen, setInformationOpen] = React.useState(false);
    const handleClick = React.useCallback((event) => {
        setInformationOpen(true);
    }, [video]);
    const handleRequestClose = React.useCallback(() => {
        setInformationOpen(false);
    }, [video]);
    const renderedTableContents = Object.entries(video.videoData.data).map(([key, value]) => {
        return (_jsxs("tr", { children: [_jsx("th", { className: "th", children: key }), _jsx("td", { className: "td", children: JSON.stringify(value) })] }, key));
    });
    return (_jsxs(_Fragment, { children: [_jsx("button", { className: "block text-bright", onClick: (event) => handleClick(event), children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: 1.5, stroke: "currentColor", className: "w-8 h-8", children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" }) }) }), _jsxs(Modal, { isOpen: informationOpen, onRequestClose: () => handleRequestClose(), children: [_jsx(ModalHeader, { children: _jsx("h2", { className: "header-2", children: "Video information" }) }), _jsxs(ModalBody, { children: [_jsx("table", { className: "table", children: _jsx("tbody", { children: renderedTableContents }) }), _jsx("dl", {})] }), _jsx(ModalControls, { children: _jsx("div", { className: "flex items-center justify-end gap-4", children: _jsx("button", { className: "btn btn-primary", onClick: () => handleRequestClose(), type: "submit", children: "Close" }) }) })] })] }));
});
export default Information;
