import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import RichTextViewer from "components/ui/RichTextViewer";
import VideoSizer from "components/ui/VideoSizer";
import Drawing from "components/ui/Drawing";
const Container = observer(({ bookmarkPage }) => {
    const video = bookmarkPage.video;
    const imageStyle = {
        aspectRatio: `${video.width}/${video.height}`,
    };
    if (bookmarkPage.videoFrame === null ||
        bookmarkPage.videoFrame.url === null) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "relative", children: [_jsx("div", { className: "absolute inset-0", children: _jsx(VideoSizer, { originalWidth: video.width, originalHeight: video.height, children: ({ width, height, scale }) => {
                                const dimensionsStyle = {
                                    width: `${width}px`,
                                    height: `${height}px`,
                                };
                                return (_jsx("div", { style: dimensionsStyle, className: "relative", children: _jsx("div", { className: "absolute inset-0 z-30", children: _jsx(Drawing, { drawingId: bookmarkPage.id, drawing: bookmarkPage.drawing.data || null, scale: scale, readOnly: true }) }) }));
                            } }) }), _jsx("img", { className: "w-full", src: bookmarkPage.videoFrame.url, style: imageStyle })] }), _jsx("div", { className: "bg-zinc-800 p-4", children: _jsx(RichTextViewer, { content: bookmarkPage.content.data }) })] }));
});
export default Container;
