import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { RouterProvider } from "react-router-dom";
import { IconContext } from "react-icons";
import { Analytics } from "@vercel/analytics/react";
import { MissingRequiredAPIs } from "services/errors";
import router from "services/routes";
function App() {
    if ("getDirectory" in navigator.storage === false ||
        "showOpenFilePicker" in window === false) {
        throw new MissingRequiredAPIs("vodon-player requires the Filesystem & FileWriter API to be present");
    }
    if ("requestVideoFrameCallback" in HTMLVideoElement.prototype === false) {
        throw new MissingRequiredAPIs("vodon-player requires the `requestVideoFrameCallback` API to be present");
    }
    return (_jsxs(_Fragment, { children: [_jsx(IconContext.Provider, { value: { size: "2rem" }, children: _jsx(RouterProvider, { router: router }) }), _jsx(Analytics, {})] }));
}
export default App;
