import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import RichTextEditor from "components/ui/RichTextEditor";
import { remove } from "services/bookmark_pages";
const Edit = observer(({ bookmark }) => {
    const selectedBookmarkPage = bookmark.selectedBookmarkPage;
    const handleClickDone = () => {
        bookmark.setEditingInProgress(false);
    };
    const handleClickDelete = () => {
        if (window.confirm("Are you sure you want to delete this bookmark page?")) {
            remove(selectedBookmarkPage);
        }
    };
    const handleContentUpdate = (content) => {
        selectedBookmarkPage.setContent(content);
    };
    const deleteText = bookmark.bookmarkPageCount === 1 ? "Delete bookmark" : "Delete page";
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "p-4", children: _jsx(RichTextEditor, { content: selectedBookmarkPage.content.data, onUpdate: (content) => handleContentUpdate(content) }) }), _jsxs("div", { className: "p-4 pt-0 flex items-center gap-4", children: [_jsx("button", { className: "btn btn-warning block w-full", onClick: () => handleClickDelete(), children: deleteText }), _jsx("button", { className: "btn btn-secondary block w-full", onClick: () => handleClickDone(), children: "Done" })] })] }));
});
export default Edit;
