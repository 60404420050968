import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Formik } from "formik";
const defaultValues = {
    name: "",
};
const Form = ({ initialValues, innerRef, onSubmit }) => {
    const handleKeyDown = (event, formik) => {
        if (event.key === "Enter") {
            event.preventDefault();
            formik.submitForm();
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Formik, { initialValues: { ...defaultValues, ...initialValues }, innerRef: innerRef, onSubmit: (values) => onSubmit(values), children: (formik) => {
                return (_jsxs(_Fragment, { children: [_jsx("label", { htmlFor: "name", className: "label", children: "Name" }), _jsx("input", { autoFocus: true, className: "input", id: "name", name: "name", onChange: formik.handleChange, type: "name", value: formik.values.name, onKeyDown: (event) => handleKeyDown(event, formik) })] }));
            } }) }));
};
export default Form;
