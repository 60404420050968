var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import consola from "consola";
import { computed } from "mobx";
import VideoFrame from "services/models/video_frame";
import { model, Model, types, tProp, idProp, findParent, modelAction, frozen, prop, } from "mobx-keystone";
import Bookmark from "./bookmark";
let BookmarkPage = class BookmarkPage extends Model({
    id: idProp,
    createdAt: tProp(types.number, Date.now()),
    content: tProp(types.frozen(types.unchecked())),
    drawing: tProp(types.frozen(types.unchecked())),
    drawingSVG: tProp(types.maybeNull(types.string), null).withSetter(),
    drawingBoundsWidth: tProp(types.maybeNull(types.number), null).withSetter(),
    drawingBoundsHeight: tProp(types.maybeNull(types.number), null).withSetter(),
    videoRef: prop(),
    videoTimestamp: tProp(types.number),
    videoFrame: tProp(types.maybeNull(types.model(VideoFrame))),
}) {
    setContent(content) {
        consola.info("Persisting content into bookmark page");
        this.content = frozen(content);
    }
    setDrawing(document) {
        consola.info("Persisting drawing into bookmark page");
        this.drawing = frozen(structuredClone(document));
    }
    /**
     * Deletes a bookmark page. Requires special care around handling the
     * removal of the bookmark itself / switching to another bookmark if the
     * deleted bookmark is the currently selected one.
     */
    delete() {
        consola.info(`Deleting bookmark page: ${this.id}`);
        // If we're the only bookmark page in the bookmark, delete the entire bookmark.
        if (this.bookmark.bookmarkPages.length === 1) {
            this.bookmark.delete();
            // If other bookmarks exist, we need to select a different one before we delete
        }
        else {
            // if we're the last bookmark in the list, select the previous bookmark by
            // default
            if (this.index === this.bookmark.bookmarkPageCount - 1) {
                this.bookmark.selectBookmarkPage(this.bookmark.bookmarkPages[this.index - 1]);
                // Otherwise select the next bookmark
            }
            else {
                this.bookmark.selectBookmarkPage(this.bookmark.bookmarkPages[this.index + 1]);
            }
            // Once we've moved off the bookmark, we can delete it
            this.bookmark.deleteBookmarkPage(this);
        }
    }
    get bookmark() {
        return findParent(this, (p) => p instanceof Bookmark);
    }
    get video() {
        return this.videoRef.current;
    }
    get session() {
        return this.video.session;
    }
    /**
     * The "session timestamp" (i.e. global time) of where this bookmark page is.
     */
    get timestamp() {
        if (this.videoRef.isValid === false) {
            return null;
        }
        return this.videoTimestamp + this.video.beginsAt;
    }
    get active() {
        return this.bookmark.selectedBookmarkPage.id === this.id;
    }
    get index() {
        return this.bookmark.bookmarkPages.findIndex((bookmarkPage) => {
            return bookmarkPage.id === this.id;
        });
    }
};
__decorate([
    modelAction
], BookmarkPage.prototype, "setContent", null);
__decorate([
    modelAction
], BookmarkPage.prototype, "setDrawing", null);
__decorate([
    modelAction
], BookmarkPage.prototype, "delete", null);
__decorate([
    computed
], BookmarkPage.prototype, "bookmark", null);
__decorate([
    computed
], BookmarkPage.prototype, "video", null);
__decorate([
    computed
], BookmarkPage.prototype, "session", null);
__decorate([
    computed
], BookmarkPage.prototype, "timestamp", null);
__decorate([
    computed
], BookmarkPage.prototype, "active", null);
__decorate([
    computed
], BookmarkPage.prototype, "index", null);
BookmarkPage = __decorate([
    model("VodonPlayer/BookmarkPage")
], BookmarkPage);
export default BookmarkPage;
