import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { usePopper } from "react-popper";
import { useOnClickOutside } from "usehooks-ts";
const PopoutControl = ({ children, open, popup, onRequestClose, }) => {
    const outsideClickRef = React.useRef(null);
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const [arrowElement, setArrowElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "right",
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            { name: "offset", options: { offset: [0, 20] } },
        ],
    });
    useOnClickOutside(outsideClickRef, (event) => {
        onRequestClose();
    });
    return (_jsxs("div", { ref: outsideClickRef, children: [_jsx("div", { ref: setReferenceElement, children: children }), open === true && (_jsx("div", { ref: setPopperElement, style: styles.popper, ...attributes.popper, children: popup }))] }));
};
export default PopoutControl;
