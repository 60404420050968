import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { TbAlertCircle } from "react-icons/tb";
import { activate } from "services/videos";
const RequestPermission = observer(({ video }) => {
    const style = { aspectRatio: `${video.width}/${video.height}` };
    const handleClick = async () => {
        await activate(video);
    };
    return (_jsxs("div", { style: style, className: "relative bg-zinc-800", children: [_jsx("div", { className: "absolute top-0 left-0 bg-black/70 px-2 z-30", children: _jsxs("h3", { children: [video.index + 1, ". ", video.name] }) }), _jsx("div", { className: "absolute inset-0 flex items-center justify-center italic z-20 bg-zinc-800", children: _jsxs("div", { className: "flex flex-col items-center", children: [_jsx(TbAlertCircle, { size: 48 }), _jsx("p", { className: "mt-2 mb-4", children: "Video requires permissions" }), _jsx("button", { className: "btn btn-primary", onClick: () => handleClick(), children: "Restore" })] }) })] }));
});
export default RequestPermission;
