import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { DashStyle } from "vendor/tldraw/index.js";
import { TbScribble, TbLineDashed, TbLineDotted, TbSquaresFilled, } from "react-icons/tb";
import PopoutControl from "components/ui/Drawing/PopoutControl";
import Tooltip from "components/ui/Tooltip";
const dashes = {
    [DashStyle.Draw]: [_jsx(TbScribble, {}), "Line"],
    [DashStyle.Solid]: [_jsx(TbSquaresFilled, {}), "Filled"],
    [DashStyle.Dashed]: [_jsx(TbLineDashed, {}), "Dashed"],
    [DashStyle.Dotted]: [_jsx(TbLineDotted, {}), "Dotted"],
};
const DrawingSelectDash = ({ app }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const currentStyle = app.useStore((s) => s.appState.currentStyle);
    const handleDashPick = React.useCallback((dash) => {
        app.style({ dash });
        setIsOpen(false);
    }, [app]);
    const handleClickSwatch = () => {
        setIsOpen(!isOpen);
    };
    const handleRequestClose = () => {
        setIsOpen(false);
    };
    const dashesData = Object.entries(dashes);
    const renderedDashes = dashesData.map(([key, [icon, label]]) => {
        return (_jsx(Tooltip, { content: label, children: _jsx("button", { className: "w-6 h-6", onClick: () => handleDashPick(key), children: icon }) }, key));
    });
    const renderedPopup = (_jsx("div", { className: "bg-stone-800 h-12 flex items-center gap-2 px-3", children: renderedDashes }));
    // @ts-ignore
    const currentDashStyle = dashes[currentStyle.dash][0];
    return (_jsx(PopoutControl, { open: isOpen, onRequestClose: () => handleRequestClose(), popup: renderedPopup, children: _jsx(Tooltip, { content: "Line style", children: _jsx("div", { className: "w-8 h-8", onClick: () => handleClickSwatch(), children: currentDashStyle }) }) }));
};
export default DrawingSelectDash;
