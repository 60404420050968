/**
 * Represents an individual frame of a video. Mostly used to comply with the
 * interface for saving files in the OPFS / remotely.
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { safeFileName } from "services/opfs";
import fileHandles from "services/file_handles";
import { liveQuery } from "dexie";
import { idProp, model, Model, tProp, types } from "mobx-keystone";
import { computed } from "mobx";
let VideoFrame = class VideoFrame extends Model({
    id: idProp,
    createdAt: tProp(types.number, Date.now()),
    url: tProp(types.maybeNull(types.string)).withSetter(),
}) {
    onAttachedToRootStore() {
        // All URLs need to start as null as they're reconstructed on loading the
        // file handle from the store
        this.setUrl(null);
        // Start observing the video storage file handle...
        const frameFileHandleObservable = liveQuery(() => fileHandles.table("videoFrameFileHandles").get({ id: this.id }));
        // When we encounter elements in this storage, we're ready to build the
        // video HTML elements. These will either be present on boot, or present
        // after we're stored a record (see the video/assets file).
        const frameFileHandleObservableDisposer = frameFileHandleObservable.subscribe({
            next: async (result) => {
                if (result === undefined) {
                    return;
                }
                const file = await result.fileHandle.getFile();
                const url = URL.createObjectURL(file);
                this.setUrl(url);
            },
            error: (error) => console.error(error),
        });
        return () => {
            frameFileHandleObservableDisposer.unsubscribe();
        };
    }
    /**
     * Path for storage of the frame
     */
    get filePath() {
        return `videoFrames/${safeFileName(this.id)}/frame.png`;
    }
};
__decorate([
    computed
], VideoFrame.prototype, "filePath", null);
VideoFrame = __decorate([
    model("VodonPlayer/VideoFrame")
], VideoFrame);
export default VideoFrame;
