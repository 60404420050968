import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { useHotkeys } from "react-hotkeys-hook";
import ReviewVideoPreview from "components/videos/ReviewVideoPreviewVideo";
const ReviewVideoList = observer(({ session }) => {
    const videos = session.videos;
    const renderedReviewVideos = videos.map((video) => (_jsx(ReviewVideoPreview, { video: video }, video.id)));
    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => {
        useHotkeys(idx.toString(), () => {
            const selectedVideo = session.indexedVideos[idx - 1];
            if (selectedVideo) {
                session.selectVideo(selectedVideo);
            }
        });
    });
    if (renderedReviewVideos.length === 0) {
        return null;
    }
    return _jsx(_Fragment, { children: renderedReviewVideos });
});
export default ReviewVideoList;
