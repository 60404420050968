import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { observer } from "mobx-react-lite";
import Modal from "components/ui/Modal";
import ModalHeader from "components/ui/ModalHeader";
import ModalBody from "components/ui/ModalBody";
import ModalControls from "components/ui/ModalControls";
import Form from "components/videos/Form";
const Edit = observer(({ video }) => {
    const formRef = React.useRef(null);
    const [editVideoOpen, setEditVideoOpen] = React.useState(false);
    const handleClickEdit = React.useCallback((event) => {
        setEditVideoOpen(true);
    }, []);
    const handleRequestClose = React.useCallback(() => {
        setEditVideoOpen(false);
    }, []);
    const handleRequestSave = React.useCallback(() => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }, []);
    const handleSubmit = React.useCallback((values) => {
        video.setName(values.name);
        setEditVideoOpen(false);
    }, []);
    const initialValues = {
        name: video.name,
    };
    return (_jsxs(_Fragment, { children: [_jsx("button", { className: "text-bright flex items-center gap-2", onClick: (event) => handleClickEdit(event), children: _jsx("h2", { className: "header-3 underline decoration-white/50 text-bright", children: video.name }) }), _jsxs(Modal, { isOpen: editVideoOpen === true, onRequestClose: () => handleRequestClose(), children: [_jsx(ModalHeader, { children: _jsx("h2", { className: "header-2", children: "Edit video" }) }), _jsx(ModalBody, { children: _jsx(Form, { innerRef: formRef, initialValues: initialValues, onSubmit: (values) => handleSubmit(values) }) }), _jsx(ModalControls, { children: _jsxs("div", { className: "flex items-center justify-end gap-4", children: [_jsx("button", { className: "btn btn-secondary", onClick: () => handleRequestClose(), children: "Cancel" }), _jsx("button", { className: "btn btn-primary", onClick: () => handleRequestSave(), type: "submit", children: "Save" })] }) })] })] }));
});
export default Edit;
