import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { secondsToHms } from "services/time";
import { activate } from "services/bookmark_pages";
import Pagination from "./Pagination";
import AddPage from "./AddPage";
import Tooltip from "components/ui/Tooltip";
const Header = observer(({ bookmark }) => {
    const rootBookmarkPage = bookmark.bookmarkPages[0];
    const videoNotReady = rootBookmarkPage.video.localFileHandlePermission === "prompt";
    /**
     * Goto the selected time. We don't need to set the active video here because
     * we'll automatically switch to the active bookmark when arriving at this
     * time.
     */
    const handleClickTimecode = () => {
        if (videoNotReady === true) {
            return;
        }
        activate(rootBookmarkPage, true);
    };
    const timecodeNotReady = (_jsx("div", { className: "flex-shrink text-sm cursor-pointer flex items-center px-4 border-r border-stone-700 select-none", children: _jsx(Tooltip, { content: "Cannot go to bookmark as the video is not ready. Please restore it", children: secondsToHms(bookmark.timestamp) }) }));
    const timecodeReady = (_jsx("div", { className: "flex-shrink text-sm cursor-pointer flex items-center px-4 border-r border-stone-700 select-none", onClick: () => handleClickTimecode(), children: secondsToHms(bookmark.timestamp) }));
    return (_jsxs("header", { className: "flex items-stretch border-b border-stone-700 h-10", children: [videoNotReady === true ? timecodeNotReady : timecodeReady, _jsx("div", { className: "flex-shrink flex items-center text-sm px-4 truncate", children: bookmark.selectedBookmarkPage.video.name }), bookmark.active === true && (_jsxs(_Fragment, { children: [_jsx("ol", { className: "flex-grow flex items-stretch justify-end", children: _jsx(Pagination, { bookmark: bookmark }) }), _jsx(AddPage, { bookmark: bookmark })] }))] }));
});
export default Header;
