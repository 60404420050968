import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import consola from "consola";
import * as React from "react";
import { observer } from "mobx-react-lite";
import useVideoControls from "services/hooks/useVideoControls";
import bus from "services/bus";
import { activate } from "services/videos";
import { TDExportType, } from "vendor/tldraw/index.js";
import Drawing from "components/ui/Drawing";
import DrawingControls from "components/ui/Drawing/DrawingControls";
import DrawingControlsKeyboardShortcuts from "components/ui/Drawing/DrawingControlsKeyboardShortcuts";
import Floating from "components/bookmarks/Floating";
import VideoNavigationControls from "components/ui/VideoNavigationControls";
import VideoNavigationKeyboardShortcuts from "components/ui/VideoNavigationKeyboardShortcuts";
import VideoSizer from "components/ui/VideoSizer";
const ReviewVideo = observer(({ hideOverlays, video, onEnableOverlays }) => {
    const selectedBookmark = video.session.activeBookmark;
    const selectedBookmarkPage = selectedBookmark?.selectedBookmarkPage;
    const videoNotReady = video.localFileHandlePermission === "prompt";
    // Track when the mouse is over the video to show the controls
    const [dimensionsReady, setDimensionsReady] = React.useState(null);
    // Track when the mouse is over the video to show the controls
    const [mouseActive, setMouseActive] = React.useState(true);
    // track when the user is actually interacting with a control area on the
    // screen, this is used to prevent the controls from being removed when
    // the user is interacting with them.
    const [controlsHovered, setControlsHovered] = React.useState(null);
    // Holds the mounted of instance of the TLDraw app. This is passed down to
    // the drawing controls widget.
    const [tlDrawInstance, setTlDrawInstance] = React.useState(null);
    const containerEl = React.useRef(null);
    const [gotoTime, pause, play, setVolume] = useVideoControls(video.reviewVideoEl);
    const handleMouseEnter = () => {
        setMouseActive(true);
    };
    const handleMouseLeave = () => {
        setMouseActive(true);
    };
    const handleControlsEnter = () => {
        setControlsHovered(true);
    };
    const handleControlsLeave = () => {
        setControlsHovered(false);
    };
    const handlePause = () => {
        pause();
        bus.emit("video.pause", video);
    };
    const handlePlay = () => {
        play();
        bus.emit("video.play", video);
    };
    const handleGotoTime = (newTime) => {
        gotoTime(newTime);
        bus.emit("video.gotoTime", video, newTime);
    };
    const handleChangeVolume = (newVolume) => {
        if (video.reviewVideoEl === null) {
            return;
        }
        video.setVolume(newVolume);
        setVolume(newVolume);
    };
    const handleTLDrawMounted = (app) => {
        consola.info("TLDraw mounted");
        setTlDrawInstance(app);
    };
    const handleDimensionsMounted = () => {
        setDimensionsReady(true);
    };
    /**
     * Persists the current drawing if we're are on a bookmark page.
     * @param document
     */
    const handlePersistDrawing = (document, scale) => {
        if (selectedBookmarkPage === undefined || tlDrawInstance === null) {
            return;
        }
        tlDrawInstance.exportImage(TDExportType.SVG, { scale, quality: 1 });
        selectedBookmarkPage.setDrawing(document);
    };
    const handleExport = async (app, info) => {
        if (selectedBookmarkPage === undefined) {
            return;
        }
        // Renable if we can use the actual SVG graphic on the print page.
        const { width, height } = app.rendererBounds;
        const content = await info.blob.text();
        selectedBookmarkPage.setDrawingSVG(content);
        selectedBookmarkPage.setDrawingBoundsWidth(width);
        selectedBookmarkPage.setDrawingBoundsHeight(height);
    };
    const handleActivateVideo = async () => {
        activate(video);
    };
    // Mount the video when it is selected
    React.useEffect(() => {
        if (video === null ||
            containerEl.current === null ||
            video.reviewVideoEl === null) {
            return;
        }
        video.reviewVideoEl.volume = video.volume;
        containerEl.current.appendChild(video.reviewVideoEl);
    }, [video.videoElementsCreated, video, dimensionsReady]);
    return (_jsxs("div", { className: "w-full h-full relative", onMouseEnter: () => handleMouseEnter(), onMouseLeave: () => handleMouseLeave(), children: [mouseActive === true && hideOverlays === false && (_jsx("div", { className: "absolute top-0 left-0 right-0 z-20 flex justify-center pointer-events-none", children: _jsx("div", { className: "bg-zinc-900/80 py-4 px-6 text-center text-2xl", children: video.name }) })), _jsx(VideoSizer, { originalWidth: video.width, originalHeight: video.height, onMount: () => handleDimensionsMounted(), children: ({ width, height, scale }) => {
                    const dimensionsStyle = {
                        width: `${width}px`,
                        height: `${height}px`,
                    };
                    return (_jsxs("div", { style: dimensionsStyle, className: "relative", children: [_jsxs("div", { className: "absolute left-0 bottom-0 z-40 text-white flex items-center gap-4", children: [videoNotReady === true && (_jsx("button", { className: "bg-red-600 px-4 py-2", onClick: () => handleActivateVideo(), children: "VIDEO REQUIRES PERMISSIONS" })), hideOverlays === true && (_jsx("button", { className: "bg-red-600 px-4 py-2", onClick: () => onEnableOverlays(), children: "REENABLE OVERLAYS" }))] }), _jsx("div", { className: "absolute inset-0 z-30", children: _jsx(Drawing, { drawingId: selectedBookmarkPage?.id || "freeform", drawing: selectedBookmarkPage?.drawing.data || null, scale: scale, onMount: (app) => handleTLDrawMounted(app), onPersist: (document) => handlePersistDrawing(document, scale), onExport: (app, info) => handleExport(app, info) }) }), selectedBookmarkPage !== undefined &&
                                selectedBookmarkPage.video.id === video.id &&
                                selectedBookmarkPage.videoFrame !== null &&
                                selectedBookmarkPage.videoFrame.url !== null && (_jsx("div", { className: "absolute inset-0 z-20 bg-zinc-700", children: _jsx("img", { className: "w-full h-full", src: selectedBookmarkPage.videoFrame.url }) })), _jsx("div", { className: "absolute inset-0 z-10 reviewVideo", ref: containerEl })] }));
                } }), video.reviewVideoEl !== null &&
                video.duration !== null &&
                video.normalisedOffset !== null &&
                video.session.duration !== null &&
                video.session.currentTime !== null &&
                hideOverlays === false &&
                (mouseActive === true || controlsHovered === true) &&
                video.frameLength !== null && (_jsx("div", { className: "absolute bottom-0 left-0 right-0 z-40 bg-zinc-900/80 p-4", onMouseEnter: () => handleControlsEnter(), onMouseLeave: () => handleControlsLeave(), children: _jsx(VideoNavigationControls, { currentTime: video.currentTime, currentTimeDisplay: video.session.currentTime, duration: video.duration, durationDisplay: video.session.duration, frameLength: video.frameLength, onChangeVolume: (newVolume) => handleChangeVolume(newVolume), onGotoTime: (time) => handleGotoTime(time), onPause: () => handlePause(), onPlay: () => handlePlay(), playing: video.reviewVideoPlaying === true, seeking: video.reviewVideoSeeking === true, videoEl: video.reviewVideoEl, volume: video.volume }) })), video.reviewVideoEl !== null && video.frameLength !== null && (_jsx(VideoNavigationKeyboardShortcuts, { frameLength: video.frameLength, keyboardShortcutsEnabled: true, onGotoTime: (time) => handleGotoTime(time), onPause: () => handlePause(), onPlay: () => handlePlay(), playing: video.reviewVideoPlaying === true, seeking: video.reviewVideoPlaying === true, videoEl: video.reviewVideoEl })), tlDrawInstance !== null &&
                hideOverlays === false &&
                (mouseActive === true || controlsHovered === true) && (_jsx("div", { className: "absolute left-0 top-0 bottom-0 z-40 flex justify-center items-center pointer-events-none", onMouseEnter: () => handleControlsEnter(), onMouseLeave: () => handleControlsLeave(), children: _jsx("div", { className: "bg-stone-800 p-2 pointer-events-auto", children: _jsx(DrawingControls, { app: tlDrawInstance }) }) })), tlDrawInstance !== null && (_jsx(DrawingControlsKeyboardShortcuts, { app: tlDrawInstance })), hideOverlays === false &&
                video.session.showBookmarksPanel === false &&
                selectedBookmark !== undefined && (_jsx("div", { className: "z-30 absolute inset-0 pointer-events-none flex items-end justify-center", style: { bottom: "150px" }, children: _jsx(Floating, { bookmark: selectedBookmark }) }))] }));
});
export default ReviewVideo;
