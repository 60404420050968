import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { usePopper } from "react-popper";
const Tooltip = ({ children, content, className = "", offset = [0, 10], }) => {
    const [hover, setHover] = React.useState(null);
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const [arrowElement, setArrowElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top",
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            { name: "offset", options: { offset } },
        ],
    });
    const handleMouseEnter = () => {
        setHover(true);
    };
    const handleMouseLeave = () => {
        setHover(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: setReferenceElement, onMouseEnter: () => handleMouseEnter(), onMouseLeave: () => handleMouseLeave(), onClick: () => handleMouseLeave(), className: className, children: children }), hover === true && (_jsxs("div", { className: "z-20", ref: setPopperElement, style: styles.popper, ...attributes.popper, children: [_jsx("div", { className: "p-2 bg-stone-800 text-sm border border-stone-700 text-center whitespace-nowrap", children: content }), _jsx("div", { ref: setArrowElement, style: styles.arrow })] }))] }));
};
export default Tooltip;
