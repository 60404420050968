import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { useRouteLoaderData } from "react-router-dom";
import bus from "services/bus";
import { bindBus, unbindBus } from "services/sessions";
import consola from "consola";
import { useHotkeys } from "react-hotkeys-hook";
import { Link } from "react-router-dom";
import { TbChevronLeft, TbChevronRight, TbArrowLeft, TbVideoOff, } from "react-icons/tb";
import AddBookmark from "components/videos/AddBookmark";
import BookmarkList from "components/sessions/BookmarkList";
import ReviewVideo from "components/videos/ReviewVideo";
import ReviewVideoList from "components/sessions/ReviewVideoList";
import Tooltip from "components/ui/Tooltip";
import React from "react";
const ReviewSession = observer(() => {
    const fullscreenTargetRef = React.useRef(null);
    const [hideOverlays, setHideOverlays] = React.useState(false);
    const [sidePanelWidth, setSidePanelWidth] = React.useState(384);
    const [availableVideoWidth, setAvailableVideoWidth] = React.useState(null);
    const { session } = useRouteLoaderData("session");
    const selectedVideo = session.selectedVideo;
    const handleToggleReviewVideosPanel = () => {
        session.toggleReviewVideoPanel();
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 0);
    };
    const handleToggleBookmarksPanel = () => {
        session.toggleBookmarksPanel();
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 0);
    };
    const handleEnableOverlays = () => {
        setHideOverlays(false);
    };
    useHotkeys("h", () => {
        setHideOverlays(!hideOverlays);
    }, [hideOverlays]);
    useHotkeys("f", () => {
        if (!document.fullscreenElement && fullscreenTargetRef.current !== null) {
            fullscreenTargetRef.current.requestFullscreen();
        }
        else if (document.exitFullscreen) {
            document.exitFullscreen();
            window.dispatchEvent(new Event("resize"));
        }
    }, []);
    // When loading the page, bind the bus events so that the preview videos
    // follow the main video
    React.useEffect(() => {
        bindBus();
        return () => {
            unbindBus();
        };
    }, []);
    // When loading the page, trigger a video realignment as the offsets may have
    // been adjusted in the setup page.
    React.useEffect(() => {
        if (selectedVideo === null) {
            return;
        }
        consola.info("Detected review page loaded, trigger video time synchronisation");
        bus.emit("video.gotoTime", selectedVideo, selectedVideo.currentTime);
    }, []);
    /**
     * Uses the width of the screen to determine the size of the sidebar panels
     * (altering this will flow into the calculation below which sets the
     * available area of the video)
     */
    React.useLayoutEffect(() => {
        const handleResize = () => {
            const minimumWidth = 250;
            const currentWidth = window.screen.width;
            const newSidePanelWidth = (112.5 + 0.125 * currentWidth + 0 * currentWidth) ^ 2;
            if (newSidePanelWidth < minimumWidth) {
                return setSidePanelWidth(minimumWidth);
            }
            setSidePanelWidth(newSidePanelWidth);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    /**
     * Calculates the available width of the video area based on the side panels
     * being open and the width of the screen.
     */
    React.useLayoutEffect(() => {
        const handleResize = () => {
            const reviewVideoPanelWidth = (() => {
                if (selectedVideo === null || session.showReviewVideoPanel === false) {
                    return 0;
                }
                return sidePanelWidth;
            })();
            const bookmarkPanelWidth = (() => {
                if (selectedVideo === null || session.showBookmarksPanel === false) {
                    return 0;
                }
                return sidePanelWidth;
            })();
            setAvailableVideoWidth(window.screen.width - reviewVideoPanelWidth - bookmarkPanelWidth);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [selectedVideo, sidePanelWidth]);
    const renderedCenterPanel = (() => {
        if (session.videos.length === 0) {
            return (_jsxs("div", { className: "w-full h-full flex flex-col items-center justify-center", children: [_jsx("div", { className: "opacity-50", children: _jsx(TbVideoOff, { size: 150 }) }), _jsxs("h2", { className: "header-2 mb-2", children: [session.name, " has no videos!"] }), _jsxs("p", { className: "paragraph text-white/50", children: [_jsx(Link, { to: `/sessions/${session.id}/setup`, className: "link", children: "Setup your videos" }), " ", "to get started"] })] }));
        }
        if (selectedVideo === null) {
            return (_jsxs("div", { className: "w-full h-full flex flex-col items-center justify-center", children: [_jsx("div", { className: "opacity-50", children: _jsx(TbArrowLeft, { size: 150 }) }), _jsx("p", { className: "mt-4 text-bright max-w-prose", children: "Select a video from the panel to get started" })] }));
        }
        return (_jsx("div", { ref: fullscreenTargetRef, className: "w-full h-full relative", children: _jsx(ReviewVideo, { video: selectedVideo, hideOverlays: hideOverlays, onEnableOverlays: () => handleEnableOverlays() }) }));
    })();
    const reviewVideoListStyle = {
        width: sidePanelWidth,
    };
    const videoStyle = {
        maxWidth: `${availableVideoWidth}px`,
    };
    const bookmarkPanelStyle = {
        width: sidePanelWidth,
    };
    return (_jsxs("div", { className: "flex items-stretch w-full h-full", children: [session.hasVideos === true && session.showReviewVideoPanel === true && (_jsx("div", { className: "border-r border-stone-700 overflow-y-auto shrink-0", style: reviewVideoListStyle, children: _jsx(ReviewVideoList, { session: session }) })), _jsxs("div", { className: "flex-grow relative", style: videoStyle, children: [session.hasVideos === true && (_jsx("button", { className: "absolute top-0 left-0 z-30", onClick: () => handleToggleReviewVideosPanel(), children: _jsx(Tooltip, { content: session.showReviewVideoPanel === true
                                ? "Close view panel"
                                : "Open video panel", children: _jsx("div", { className: "w-12 h-12 p-2 bg-stone-800 flex items-center justify-center", children: session.showReviewVideoPanel === true ? (_jsx(TbChevronLeft, {})) : (_jsx(TbChevronRight, {})) }) }) })), renderedCenterPanel, selectedVideo !== null && (_jsx("button", { className: "absolute top-0 right-0 z-30", onClick: () => handleToggleBookmarksPanel(), children: _jsx(Tooltip, { content: session.showBookmarksPanel === true
                                ? "Close bookmarks panel"
                                : "Open bookmarks panel", children: _jsx("div", { className: "w-12 h-12 p-2 bg-stone-800 flex items-center justify-center", children: session.showBookmarksPanel === true ? (_jsx(TbChevronRight, {})) : (_jsx(TbChevronLeft, {})) }) }) }))] }), selectedVideo !== null && session.showBookmarksPanel === true && (_jsxs("div", { className: "border-l border-stone-700 overflow-y-auto shrink-0 flex flex-col", style: bookmarkPanelStyle, children: [_jsx("div", { className: "flex-grow overflow-y-auto", children: _jsx(BookmarkList, { session: session }) }), _jsx("div", { className: "border-t border-stone-700 p-4", children: _jsx(AddBookmark, { video: selectedVideo }) })] }))] }));
});
export default ReviewSession;
