import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter } from "react-router-dom";
import store from "services/models/root";
import { MissingParameter, RecordNotFound } from "services/errors";
import RootLayout from "layouts/Root";
import SessionLayout from "layouts/Session";
import ManageSessions from "pages/ManageSessions";
import ReviewSession from "pages/ReviewSession";
import SetupSession from "pages/SetupSession";
import PrintSession from "pages/PrintSession";
const router = createBrowserRouter([
    {
        element: _jsx(RootLayout, {}),
        path: "/",
        children: [
            {
                element: _jsx(ManageSessions, {}),
                index: true,
            },
            {
                element: _jsx(SessionLayout, {}),
                path: "sessions/:id",
                id: "session",
                loader: ({ params }) => {
                    if (!params.id) {
                        throw new MissingParameter("Path for session was missing its id");
                    }
                    const session = store.getSessionById(params.id);
                    if (!session) {
                        throw new RecordNotFound(`Could not find session with id: ${params.id}`);
                    }
                    return {
                        session,
                    };
                },
                children: [
                    {
                        element: _jsx(ReviewSession, {}),
                        path: "review",
                    },
                    {
                        element: _jsx(SetupSession, {}),
                        path: "setup",
                    },
                    {
                        element: _jsx(PrintSession, {}),
                        path: "print",
                    },
                ],
            },
        ],
    },
]);
export default router;
