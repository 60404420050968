import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { create as createBookmarkPage } from "services/bookmark_pages";
import Tooltip from "components/ui/Tooltip";
const AddPage = observer(({ bookmark }) => {
    const handleClick = async () => {
        if (bookmark.session.selectedVideo === null) {
            return;
        }
        const bookmarkPage = await createBookmarkPage(bookmark.session.selectedVideo, bookmark.selectedBookmarkPage.content.data);
        bookmark.addBookmarkPage(bookmarkPage);
        bookmark.selectBookmarkPage(bookmarkPage);
        bookmark.setEditingInProgress(true);
        bookmark.setActive(true);
    };
    const classes = classNames({
        "border-l border-slate-700 px-2": true,
        "cursor-not-allowed": bookmark.editingInProgress === true,
    });
    return (_jsx(Tooltip, { className: "h-full flex items-center justify-center", content: "Add page to bookmark", offset: [0, 5], children: _jsx("button", { className: classes, onClick: () => handleClick(), disabled: bookmark.editingInProgress === true, children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: 1.5, stroke: "currentColor", className: "w-6 h-6", children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M12 4.5v15m7.5-7.5h-15" }) }) }) }));
});
export default AddPage;
