import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import SetupVideo from "../videos/SetupVideo";
const SetupVideoList = observer(({ session }) => {
    const renderedSetupVideos = session.videos.map((video) => (_jsx(SetupVideo, { video: video }, video.id)));
    if (renderedSetupVideos.length === 0) {
        return null;
    }
    return _jsx(_Fragment, { children: renderedSetupVideos });
});
export default SetupVideoList;
