import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { useRouteLoaderData } from "react-router-dom";
import { secondsToHms } from "services/time";
import PrintTile from "components/bookmark_pages/PrintTile";
const PrintSession = observer(() => {
    const data = useRouteLoaderData("session");
    const session = data.session;
    const renderedBookmarks = session.sortedBookmarks.map((bookmark) => {
        const renderedBookmarkPages = bookmark.sortedBookmarkPages.map((bookmarkPage) => {
            return (_jsx("div", { className: "flex flex-col", children: _jsx(PrintTile, { bookmarkPage: bookmarkPage }) }, bookmarkPage.id));
        });
        return (_jsxs("div", { className: "mb-8", children: [_jsx("h2", { className: "header-2 mb-4", children: secondsToHms(bookmark.timestamp) }), _jsx("div", { className: "grid grid-cols-3 gap-4", children: renderedBookmarkPages })] }, bookmark.id));
    });
    return (_jsxs("div", { className: "w-full p-8", children: [_jsx("h1", { className: "header-1 text-center mb-8", children: session.name }), renderedBookmarks] }));
});
export default PrintSession;
