import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import Header from "components/bookmarks/Header";
const Container = observer(({ children, bookmark }) => {
    const classes = classNames({
        "border-b border-stone-700": true,
        "opacity-60": bookmark.active === false,
    });
    return (_jsxs("div", { className: classes, children: [_jsx(Header, { bookmark: bookmark }), children] }));
});
export default Container;
