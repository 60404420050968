import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { remove } from "services/videos";
const Delete = observer(({ video }) => {
    const handleClick = React.useCallback(async (event) => {
        if (window.confirm("Remove this video?")) {
            await remove(video);
        }
    }, [video]);
    return (_jsx("button", { className: "block text-red-600", onClick: (event) => handleClick(event), children: _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: 1.5, stroke: "currentColor", className: "w-8 h-8", children: _jsx("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" }) }) }));
});
export default Delete;
