import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import Show from "components/bookmarks/Show";
import Edit from "components/bookmarks/Edit";
import Container from "components/bookmarks/Container";
const BookmarkList = observer(({ session }) => {
    const renderedBookmarks = session.sortedBookmarks.map((bookmark) => {
        if (bookmark.editingInProgress === true) {
            return (_jsx(Container, { bookmark: bookmark, children: _jsx(Edit, { bookmark: bookmark }) }, bookmark.id));
        }
        else {
            return (_jsx(Container, { bookmark: bookmark, children: _jsx(Show, { bookmark: bookmark }) }, bookmark.id));
        }
    });
    if (renderedBookmarks.length === 0) {
        return (_jsx("div", { className: "w-full h-full flex items-center justify-center p-4 text-center", children: _jsx("em", { className: "text-white/30", children: "No bookmarks created on this video" }) }));
    }
    return _jsx(_Fragment, { children: renderedBookmarks });
});
export default BookmarkList;
