import { rootRef, detach } from "mobx-keystone";
export const videoRef = rootRef("VodonPlayer/VideoRef", {
    onResolvedValueChange(ref, newVideo, oldVideo) {
        if (oldVideo && !newVideo) {
            detach(ref);
        }
    },
});
export const bookmarkRef = rootRef("VodonPlayer/BookmarkRef", {
    onResolvedValueChange(ref, newBookmark, oldBookmark) {
        if (oldBookmark && !newBookmark) {
            detach(ref);
        }
    },
});
export const bookmarkPageRef = rootRef("VodonPlayer/BookmarkPageRef", {
    onResolvedValueChange(ref, newBookmarkPage, oldBookmarkPage) {
        if (oldBookmarkPage && !newBookmarkPage) {
            detach(ref);
        }
    },
});
