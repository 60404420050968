import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "components/ui/Modal";
import ModalHeader from "components/ui/ModalHeader";
import ModalBody from "components/ui/ModalBody";
import ModalControls from "components/ui/ModalControls";
import Form from "components/sessions/Form";
const Edit = observer(({ session }) => {
    const formRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const handleRequestClose = () => {
        setOpen(false);
    };
    const handleRequestUpdate = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };
    const handleSubmit = (values) => {
        session.update(values);
        setOpen(false);
    };
    const handleClick = () => {
        setOpen(true);
    };
    const initialValues = {
        name: session.name,
    };
    return (_jsxs(_Fragment, { children: [_jsx("button", { className: "btn btn-primary", onClick: () => handleClick(), children: "Edit" }), _jsxs(Modal, { isOpen: open === true, onRequestClose: () => handleRequestClose(), children: [_jsx(ModalHeader, { children: _jsx("h2", { className: "header-2", children: "Update session" }) }), _jsx(ModalBody, { children: _jsx(Form, { innerRef: formRef, onSubmit: (values) => handleSubmit(values), initialValues: initialValues }) }), _jsx(ModalControls, { children: _jsxs("div", { className: "flex items-center justify-end gap-4", children: [_jsx("button", { className: "btn btn-secondary", onClick: () => handleRequestClose(), children: "Cancel" }), _jsx("button", { className: "btn btn-primary", onClick: () => handleRequestUpdate(), type: "submit", children: "Update" })] }) })] })] }));
});
export default Edit;
