import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import RichTextViewer from "components/ui/RichTextViewer";
import { activate } from "services/bookmark_pages";
const Show = observer(({ bookmark }) => {
    const handleClick = () => {
        activate(bookmark.selectedBookmarkPage, true);
        bookmark.setEditingInProgress(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "p-4", children: _jsx(RichTextViewer, { content: bookmark.selectedBookmarkPage.content.data }) }), _jsx("div", { className: "p-4 pt-0", children: _jsx("button", { className: "btn btn-secondary block w-full", onClick: () => handleClick(), children: "Edit" }) })] }));
});
export default Show;
