var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import consola from "consola";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { localStorageKey } from "services/state";
import examples from "services/examples";
import { MissingRequiredAPIs } from "services/errors";
import { fromSnapshot, getSnapshot, model, Model, modelAction, registerRootStore, setGlobalConfig, tProp, types, } from "mobx-keystone";
import { reaction, computed } from "mobx";
import Session from "./session";
import Video from "./video";
setGlobalConfig({
    // use uuidv4 to generate client side unique ids that can be saved to the
    // server later (once we have the backend integration ready)
    modelIdGenerator: uuidv4,
});
const getInitalState = () => {
    if (typeof Storage === "undefined") {
        throw new MissingRequiredAPIs("The LocalStorage API is required to use the app");
    }
    const storedData = localStorage.getItem(localStorageKey);
    return !storedData ? null : JSON.parse(storedData);
};
let RootStore = class RootStore extends Model({
    sessions: tProp(types.array(types.model(Session)), () => []),
    videos: tProp(types.array(types.model(Video)), () => []),
    importedDefaultExamples: tProp(types.boolean, false).withSetter(),
    latestChangelogReadHash: tProp(types.maybeNull(types.number), null).withSetter(),
    _version: tProp(1),
}) {
    // On changes to the root store, persist them to localstorage
    onAttachedToRootStore() {
        const reactionDisposer = reaction(() => getSnapshot(this), async (sn) => {
            // On change, store a snapshot of the current root store to
            // localstorage
            localStorage.setItem(localStorageKey, JSON.stringify(sn));
        }, {
            fireImmediately: true,
        });
        /*
        if (this.importedDefaultExamples === false) {
          console.info(
            "Detected that example sessions have not imported, importing them now"
          );
          this.importDefaultExamples();
          this.setImportedDefaultExamples(true);
        }
        */
        return () => {
            reactionDisposer();
        };
    }
    // Sessions
    getSessionById(id) {
        return this.sessions.find((session) => session.id === id);
    }
    addSession(session) {
        consola.info(`Adding session: ${session.name}`);
        this.sessions.push(session);
    }
    removeSession(session) {
        consola.info(`Removing session: ${session.name}`);
        this.sessions = this.sessions.filter((innerSession) => {
            return innerSession.id !== session.id;
        });
    }
    upsertSession(session) {
        this.removeSession(session);
        this.sessions.push(session);
    }
    importDefaultExamples() {
        examples.forEach((example) => {
            this.addSession(fromSnapshot(Session, example));
        });
    }
    get sessionsCount() {
        return this.sessions.length;
    }
    get sortedSessions() {
        return [...this.sessions].sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }
};
__decorate([
    modelAction
], RootStore.prototype, "addSession", null);
__decorate([
    modelAction
], RootStore.prototype, "removeSession", null);
__decorate([
    modelAction
], RootStore.prototype, "upsertSession", null);
__decorate([
    modelAction
], RootStore.prototype, "importDefaultExamples", null);
__decorate([
    computed
], RootStore.prototype, "sessionsCount", null);
__decorate([
    computed
], RootStore.prototype, "sortedSessions", null);
RootStore = __decorate([
    model("VodonPlayer/RootStore")
], RootStore);
export { RootStore };
const initalState = getInitalState();
const myRootStore = initalState === null
    ? new RootStore({})
    : fromSnapshot(RootStore, initalState);
registerRootStore(myRootStore);
export const RootStoreContext = React.createContext(myRootStore);
export default myRootStore;
