import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { observer } from "mobx-react-lite";
import ReviewVideoPreviewMessage from "components/videos/ReviewVideoPreviewMessage";
import RequestPermission from "components/videos/RequestPermission";
import ReplaceVideo from "components/videos/ReplaceVideo";
import { MessageKind } from "components/videos/ReviewVideoPreviewMessage";
const ReviewVideoPreview = observer(({ video }) => {
    const containerEl = React.useRef(null);
    const selectedVideo = video.session.selectedVideo;
    const handleClick = () => {
        if (video.beforePlayableRange === true ||
            video.afterPlayableRange === true) {
            return;
        }
        video.session.selectVideo(video);
    };
    React.useEffect(() => {
        if (containerEl.current === null ||
            video.reviewVideoEl === null ||
            (selectedVideo !== null && selectedVideo.id === video.id) ||
            video.afterPlayableRange === true ||
            video.beforePlayableRange === true ||
            video.videoElementsCreated === false ||
            video.localFileHandlePermission !== "granted") {
            return;
        }
        video.reviewVideoEl.volume = 0;
        containerEl.current.appendChild(video.reviewVideoEl);
    }, [
        selectedVideo,
        video.afterPlayableRange,
        video.beforePlayableRange,
        video.videoElementsCreated,
        video.localFileHandlePermission,
    ]);
    // If we don't have permissions to access the video, display a special
    // clickable component which can be used to rerequest permission to access
    // the video again
    if (video.localFileHandlePermission === "prompt") {
        return _jsx(RequestPermission, { video: video });
    }
    if (video.fileMissing === true) {
        return _jsx(ReplaceVideo, { video: video });
    }
    // We can't display the video in two places, so set a message instead here
    if (selectedVideo !== null && selectedVideo.id === video.id) {
        return (_jsx(ReviewVideoPreviewMessage, { video: video, messageKind: MessageKind.Playing }));
    }
    // Before the range that this video is visible in, set a message
    if (video.beforePlayableRange === true) {
        return (_jsx(ReviewVideoPreviewMessage, { video: video, messageKind: MessageKind.BeforeRange }));
    }
    // After the range that this video is visible in, set a message
    if (video.afterPlayableRange === true) {
        return (_jsx(ReviewVideoPreviewMessage, { video: video, messageKind: MessageKind.AfterRange }));
    }
    const style = { aspectRatio: `${video.width}/${video.height}` };
    return (_jsxs("div", { style: style, className: "relative bg-zinc-800 flex items-stretch justify-items-stretch cursor-pointer", onClick: () => handleClick(), children: [_jsx("div", { className: "absolute top-0 left-0 bg-black/70 px-2 z-30", children: _jsxs("h3", { children: [video.index + 1, ". ", video.name] }) }), _jsx("div", { className: "absolute inset-0 z-10 reviewVideo z-10", ref: containerEl })] }));
});
export default ReviewVideoPreview;
