import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { ColorStyle } from "vendor/tldraw/index.js";
import PopoutControl from "components/ui/Drawing/PopoutControl";
import Tooltip from "components/ui/Tooltip";
const colors = {
    [ColorStyle.White]: "#f0f1f3",
    [ColorStyle.LightGray]: "#c6cbd1",
    [ColorStyle.Gray]: "#788492",
    [ColorStyle.Black]: "#1d1d1d",
    [ColorStyle.Green]: "#36b24d",
    [ColorStyle.Cyan]: "#0e98ad",
    [ColorStyle.Blue]: "#1c7ed6",
    [ColorStyle.Indigo]: "#4263eb",
    [ColorStyle.Violet]: "#7746f1",
    [ColorStyle.Red]: "#ff2133",
    [ColorStyle.Orange]: "#ff9433",
    [ColorStyle.Yellow]: "#ffc936",
};
const DrawingSelectColour = ({ app }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const currentStyle = app.useStore((s) => s.appState.currentStyle);
    const handleColourPick = React.useCallback((color) => {
        app.style({ color });
        setIsOpen(false);
    }, [app]);
    const handleClickSwatch = () => {
        setIsOpen(!isOpen);
    };
    const handleRequestClose = () => {
        setIsOpen(false);
    };
    const swatchesData = Object.entries(colors);
    const renderedSwatches = swatchesData.map(([key, value]) => {
        const style = {
            backgroundColor: value,
        };
        return (_jsx(Tooltip, { content: key, children: _jsx("button", { style: style, className: "w-6 h-6", onClick: () => handleColourPick(key) }) }, key));
    });
    const currentSwatchStyle = {
        // @ts-ignore
        backgroundColor: colors[currentStyle.color],
    };
    const renderedPopup = (_jsx("div", { className: "bg-stone-800 h-12 flex items-center gap-2 px-3", children: renderedSwatches }));
    return (_jsx(PopoutControl, { open: isOpen, onRequestClose: () => handleRequestClose(), popup: renderedPopup, children: _jsx(Tooltip, { content: "Line colour", children: _jsx("div", { className: "w-8 h-8", style: currentSwatchStyle, onClick: () => handleClickSwatch() }) }) }));
};
export default DrawingSelectColour;
