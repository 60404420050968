import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { remove as removeSession } from "services/sessions";
const Delete = observer(({ session }) => {
    const handleClick = () => {
        if (window.confirm("Remove this session?")) {
            removeSession(session);
        }
    };
    return (_jsx("button", { className: "btn btn-primary", onClick: () => handleClick(), children: "Delete" }));
});
export default Delete;
